import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
    IPermissionCategory,
    IRole,
    IRolePOST,
    IRolePUT,
    IUser,
    IUserApiConfig,
    IUserPOST,
    IUserPUT,
    IUserProfile,
    IUserProfilePUT,
    IUserTenantInvitation,
    IUserTenantInvitationDetails,
} from '../../models';
import { BaseRepositoryService } from './base-repository.service';

@Injectable({ providedIn: 'root' })
export class UserApiService extends BaseRepositoryService {
    constructor() {
        super('userApi');
    }

    public getMe(): Observable<IUser> {
        return this.getApiUrl('/users/me', 'v2').pipe(mergeMap((url) => this.http.get<IUser>(url)));
    }

    public getMeWithPermissions(tenantId: string): Observable<IUser> {
        return this.getApiUrl('/users/me', 'v2').pipe(
            mergeMap((url) => this.http.get<IUser>(url, { headers: { 'OneGo-Tenant': tenantId } }))
        );
    }

    public getUser(userId: string): Observable<IUser> {
        return this.getApiUrl(`/users/${userId}`).pipe(mergeMap((url) => this.http.get<IUser>(url)));
    }

    public getUsers(): Observable<Array<IUser>> {
        return this.getApiUrl('/users').pipe(mergeMap((url) => this.http.get<Array<IUser>>(url)));
    }

    public postUser(user: IUserPOST): Observable<IUser> {
        return this.getApiUrl('/users').pipe(mergeMap((url) => this.http.post<IUser>(url, user)));
    }

    public putUser(userId: string, user: IUserPUT): Observable<IUser> {
        return this.getApiUrl(`/users/${userId}`).pipe(mergeMap((url) => this.http.put<IUser>(url, user)));
    }

    public putProfile(userId: string, user: IUserProfilePUT): Observable<IUserProfile> {
        return this.getApiUrl(`/users/${userId}/profile`).pipe(
            mergeMap((url) => this.http.put<IUserProfile>(url, user))
        );
    }

    public archiveUser(userId: string): Observable<IUser> {
        return this.getApiUrl(`/users/${userId}/tenant`).pipe(mergeMap((url) => this.http.delete<IUser>(url)));
    }

    public getRoles(): Observable<Array<IRole>> {
        return this.getApiUrl('/roles').pipe(mergeMap((url) => this.http.get<Array<IRole>>(url)));
    }

    public createRole(role: IRolePOST): Observable<IRole> {
        return this.getApiUrl('/roles').pipe(mergeMap((url) => this.http.post<IRole>(url, role)));
    }

    public putRole(roleId: string, role: IRolePUT): Observable<IRole> {
        return this.getApiUrl(`/roles/${roleId}`).pipe(mergeMap((url) => this.http.put<IRole>(url, role)));
    }

    public deleteRole(id: string): Observable<IRole> {
        return this.getApiUrl(`/roles/${id}`).pipe(mergeMap((url) => this.http.delete<IRole>(url)));
    }

    public getConfig(): Observable<IUserApiConfig> {
        return this.getApiUrl('/config').pipe(mergeMap((url) => this.http.get<IUserApiConfig>(url)));
    }

    public getPermissionCategories(): Observable<Array<IPermissionCategory>> {
        return this.getApiUrl('/permissions/categories').pipe(
            mergeMap((url) => this.http.get<Array<IPermissionCategory>>(url, {}))
        );
    }

    public getProfile(): Observable<IUserProfile> {
        return this.getApiUrl('/users/me/profile').pipe(mergeMap((url) => this.http.get<IUserProfile>(url)));
    }

    public postInvitation(): Observable<IUserTenantInvitation> {
        return this.getApiUrl('/tenants/invitations').pipe(
            mergeMap((url) => this.http.post<IUserTenantInvitation>(url, null))
        );
    }

    public getInvitation(token: string): Observable<IUserTenantInvitationDetails> {
        return this.getApiUrl(`/tenants/invitations/${token}`, 'v1').pipe(
            mergeMap((url) => this.http.get<IUserTenantInvitationDetails>(url))
        );
    }

    public acceptInvitation(token: string): Observable<void> {
        return this.getApiUrl(`/tenants/`, 'v1').pipe(mergeMap((url) => this.http.post<void>(url, { token })));
    }
}
